var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3a80ca5e29fd32691cc38b8778615fbde2da1e55"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_ENV = process.env.SENTRY_ENVIRONMENT

Sentry.init({
  environment: process.env.SENTRY_ENVIRONMENT,
  dsn:
    SENTRY_DSN ||
    'https://16e86d93fcf84a98a0b23baf5ff2d01b@o1295580.ingest.sentry.io/6525056',
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 0.2, // medium
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampler: (samplingContext) => {
    if (samplingContext.transactionContext.tags?.sampleRate === 'low') {
      return 0.1
    }
    if (samplingContext.transactionContext.tags?.sampleRate === 'medium') {
      return 0.2
    }
    return 1
  },
  beforeSend: (event, hint) => {
    if (SENTRY_ENV !== 'production') {
      console.error(hint.originalException || hint.syntheticException)
    }
    //Disable sending event to sentry from localhost
    return SENTRY_ENV === 'development' ? null : event
  },
  integrations: [new Sentry.Replay()],
})
