import { gql } from 'graphql-request'

export const PRODUCT_VARIANT_FRAGMENT = gql`
  fragment MoneyVariantFragment on MoneyV2 {
    amount
    currencyCode
  }
  fragment ProductVariantImageFragment on Image {
    url
    width
    height
    altText
    id
  }

  fragment ProductVariantFragment on ProductVariant {
    id
    title
    sku
    quantityAvailable
    availableForSale
    currentlyNotInStock
    image {
      ...ProductVariantImageFragment
    }
    price {
      ...MoneyVariantFragment
    }
    compareAtPrice {
      ...MoneyVariantFragment
    }
    product {
      id
      title
      handle
      vendor
      productType
      colors: metafield(key: "color", namespace: "product") {
        key
        value
      }
      vipProduct: metafield(key: "vip_product", namespace: "product") {
        key
        value
      }
      hideSwatch: metafield(key: "hideswatch", namespace: "product") {
        key
        value
      }
      preOrderIndicator: metafield(
        key: "pre_order_indicator"
        namespace: "product"
      ) {
        key
        value
      }
      preOrderMessage: metafield(
        key: "pre_order_message"
        namespace: "product"
      ) {
        key
        value
      }
      greyBadge: metafield(key: "grey_badge", namespace: "product") {
        key
        value
      }
      finalSale: metafield(key: "final_sale", namespace: "product") {
        key
        value
      }
      allowToBuyOnlyOneItem: metafield(
        key: "allow_to_buy_only_one_item"
        namespace: "product"
      ) {
        key
        value
      }
    }
    selectedOptions {
      name
      value
    }
  }
`
